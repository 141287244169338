import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" alt="An image showing two different avatars, one larger and one smaller, to represent a parent-child combination." src="https://user-images.githubusercontent.com/586552/203213337-c7d0f683-4971-4ef3-b480-c4743b929634.png" />
    <h2>{`Usage`}</h2>
    <p>{`Use avatar pair to display two avatars when one is secondary to the other.`}</p>
    <h2>{`Options`}</h2>
    <p>{`Avatar pair has two parent-child combinations: the parent as the circle and the child as the square, and vice-versa.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} mdxType="Box">
  <div>
    <img src="https://user-images.githubusercontent.com/586552/203212171-7fff46e1-e250-4b9f-8e4c-a9acab8eec8b.png" alt="" />
    <Caption mdxType="Caption">Parent as the circle avatar (human) and child as the square (entity).</Caption>
  </div>
  <div>
    <img src="https://user-images.githubusercontent.com/586552/203212177-e872b53e-6a2c-4539-a148-a5d86298fde6.png" alt="" />
    <Caption mdxType="Caption">Parent as the square avatar (entity) and child as the circle (human).</Caption>
  </div>
    </Box>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="AvatarPair" mdxType="AccessibilityLink" />
    <h2>{`Related components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/avatar"
        }}>{`Avatar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/avatar-stack"
        }}>{`Avatar stack`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      